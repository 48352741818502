<template>
  <div class="recharge container van-tab__panel" :style="{ backgroundImage: 'url(img/pro-bg.png)' }">
    <van-row>
      <van-col span="6" class="text-center">
        <p style="margin-top:20px">账户资金</p>
      </van-col>
      <van-col span="18">
        <h2 class="green">{{ userInfo.info.balance }}</h2>
      </van-col>
      <!-- <van-col span="12" class="fz12 txt-right">
        <p>昨日回款额：<strong class="blue">5295888.62</strong></p>
        <p>今日回款额：<strong class="red">2393647.91</strong></p>
      </van-col> -->
    </van-row>
    <van-loading v-if="loading" />
    <div class="txt-center con mt-10" v-if="!loading&&!paymentList.length">
      暂无充值渠道
    </div>
    <div class="card shadow default border-r5 con mt-10" @click="rechargeType(item)" v-for="item in paymentList"
      :key="item.id">
      <van-icon v-if="item.payment_type==='transfer'" name="card" style="margin-left: 6px" />
      <van-icon v-else-if="item.payment_type ==='agent_chat'" name="service-o" style="margin-left: 6px" />
      <svg-icon v-else icon-class="USDT" style="width: 50px; height: 50px; margin-right: 15px" />
      <span>{{item.front_name}}</span>
      <van-icon name="arrow" style="font-size: 14px" />
    </div>
  </div>
</template>

<script setup>
 import { ref } from "vue";
import store from "@/store";
import { getDeposit } from "@/api/money";
import { useRouter } from "vue-router";
const router = useRouter();
const loading=ref(true);
const { userInfo } = store.getters
const rechargeType = (item) => {
  router.push({ name: "rechargeType", query: { type: item.front_name, id: item.id, payment_type:item.payment_type } });
};
const paymentList = ref([]);
getDeposit().then((res) => {
  paymentList.value = res.data.user_payment_accounts;
  loading.value=false
}).catch(()=>{
  loading.value=false
});
</script>

<style lang="less" scoped>
.recharge {
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: 0 -50px;
  color: #ddd;

  .white {
    margin: 0;
  }

  .card {
    display: flex;
    justify-content: left;
    /* 水平居中 */
    align-items: center;
    justify-content: space-between;
    font-size: 18px;

    i:first-child {
      font-size: 40px;
      margin-right: 20px;
    }

    span {
      width: 80%;
    }
  }
}
</style>
